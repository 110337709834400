<template>
  <v-card elevation="0">
    <v-progress-linear v-if="loading" indeterminate color="accent" />

    {{ error }}

    <ul>
      <li v-for="orderId in orders" :key="orderId">
        <span class="label">{{ $t('orders.order') }} {{ orderId }}:</span>
        <ul>
          <li>{{ parcelList(orderId) }}</li>
        </ul>
      </li>
    </ul>
  </v-card>
</template>

<script>
import ParcelService from '@/services/ParcelService.js';
export default {
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      loading: false,
      parcels: {},
      error: null
    };
  },
  computed: {
    orders() {
      return [...Object.keys(this.parcels)];
    }
  },
  watch: {
    shipment: {
      async handler(val, oldVal) {
        await this.load();
      },
      deep: true
    }
  },
  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      this.loading = true;
      this.error = null;
      this.parcels = {};
      try {
        console.log('loading parcels for shipment.id=', this.shipment.id);
        let rsp = await ParcelService.getItems(
          {
            filters: [
              {
                field: 'shipment_id',
                operation: '=',
                value: this.shipment.id
              }
            ]
          },
          ''
        );
        let parcels = rsp[0];
        // console.log("parcels loaded:", parcels);

        if (parcels.length != this.shipment.parcel_count) {
          this.error = `Only ${parcels.length} parcels found, but ${this.shipment.parcel_count} expected`;
        }

        this.parcels = parcels.reduce((acc, p) => {
          if (!acc[p.order]) {
            acc[p.order] = [];
          }
          acc[p.order].push(p);
          return acc;
        }, {});

        // console.log("parcels grouped:", this.parcels)
      } catch (error) {
        console.log('error:', error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    parcelList(orderId) {
      // console.log("parcelList(", orderId,")")
      return this.parcels[orderId].map(x => x.id).join(', ');
    }
  }
};
</script>

<style lang="sass">
li .label
  font-weight: 500
  padding
</style>
